import { RedeemPayload, Success } from 'global';
import axios from './config';

class CouponApi {
  public static redeem(payload: RedeemPayload) {
    return axios.post<Success>('/redeem', payload);
  }

  public static resendEmail(payload: RedeemPayload) {
    return axios.post('/resendEmail', payload);
  }
}

export default CouponApi;

import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { RedeemError, Success } from 'global';
import * as React from 'react';
import CouponApi from './api/CouponApi';
import Spinner from './Spinner';

import './App.css';

type Form = {
  branchCode: string;
  redemptionCode: string;
  serialNumber: string;
};

type SubmissionState = {
  success?: Success;
  error?: RedeemError;
  submissionInFlight: boolean;
};

type State = Form & SubmissionState & { resendEmailState: ResendEmailState };

interface Init {
  type: 'Init';
}

interface InFlight {
  type: 'InFlight';
}

interface Failed {
  type: 'Failed';
}

interface Done {
  type: 'Done';
}

type ResendEmailState = Init | InFlight | Failed | Done;

type Props = {};

class App extends React.Component<Props, State> {
  private redemptionCode: React.RefObject<HTMLInputElement>;

  constructor(props: Props) {
    super(props);
    this.state = {
      branchCode: '',
      redemptionCode: '',
      serialNumber: '',
      error: undefined,
      success: undefined,
      submissionInFlight: false,
      resendEmailState: {
        type: 'Init',
      },
    };
    this.redemptionCode = React.createRef();
  }
  public render(): React.ReactNode {
    const {
      redemptionCode,
      branchCode,
      serialNumber,
      submissionInFlight,
    } = this.state;
    return (
      <div className="App">
        <img src={require('./assets/samsung_logo.png')} className="logo" />
        <form onSubmit={this.onSubmit}>
          <div className="inputContainer">
            <label htmlFor="branchCode">Branch Code</label>
            <input
              name="branchCode"
              type="text"
              onChange={this.handleChange}
              value={branchCode}
            />
          </div>
          <div className="inputContainer">
            <label htmlFor="redemptionCode">Redemption Code</label>
            <input
              ref={this.redemptionCode}
              type="text"
              onChange={this.handleChange}
              name="redemptionCode"
              value={redemptionCode}
            />
          </div>
          <div className="inputContainer">
            <label htmlFor="serialNumber">Mobile Number</label>
            <input
              type="text"
              onChange={this.handleChange}
              name="serialNumber"
              value={serialNumber}
            />
          </div>
          {this.renderSuccessMsg()}
          {this.renderErrorMsg()}
          <p className="submitAgainMessage">Enter another code</p>
          <button type="submit" className="submitBtn">
            {submissionInFlight ? <Spinner /> : 'ENTER'}
          </button>
        </form>
      </div>
    );
  }

  private onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { redemptionCode, branchCode, serialNumber } = this.state;
    if (!this.state.submissionInFlight) {
      this.setState({
        submissionInFlight: true,
        success: undefined,
        error: undefined,
        resendEmailState: {
          type: "Init"
        }
      });
      try {
        const res = await CouponApi.redeem({
          redemptionCode,
          branchCode,
          serialNumber,
        });
        this.setState({
          success: res.data,
          error: undefined,
        });
      } catch (err: any) {
        const { response } = { ...err } as AxiosError;
        console.log(err);
        this.setState({
          success: undefined,
          error: response
            ? (response.data as RedeemError)
            : ({ type: 'UnknownError' } as RedeemError),
        });
      } finally {
        if (this.redemptionCode.current) {
          this.redemptionCode.current.select();
        }
        this.setState({
          submissionInFlight: false,
        });
      }
    }
  };
  private handleChange: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget: { name, value },
  }) => {
    if (
      name === 'branchCode' ||
      name === 'redemptionCode' ||
      name === 'serialNumber'
    ) {
      this.setState({
        [name]: value,
      } as any); // current work around due to ts limitation in typing
    }
  };

  private resendEmail = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const { redemptionCode, branchCode, serialNumber } = this.state;
    this.setState(s => ({...s, resendEmailState: { type: 'InFlight' } }));
    CouponApi.resendEmail({ redemptionCode, branchCode, serialNumber })
      .then(() => {
        this.setState(s => ({ ...s, resendEmailState: { type: 'Done' } }));
      })
      .catch(() => {
        this.setState(s => ({ ...s, resendEmailState: { type: 'Failed' } }));
      });
  };

  private renderSuccessMsg = (): React.ReactNode => {
    const { success } = this.state;
    if (success) {
      return (
        <React.Fragment>
          <p className="message">
            Code successfully redeemed
            {success.redemptionBranch && (
              <span>
                {' '}
                at{' '}
                <u>
                  {success.redemptionMerchant} ({success.redemptionBranch})
                </u>
              </span>
            )}
            !
          </p>
          <p className="message">Ref #: {success.rowId}</p>
          <p className="couponName">
            <b>{success.promoName}</b>
          </p>
        </React.Fragment>
      );
    }
    return undefined;
  };

  private renderErrorMsg = (): React.ReactNode => {
    const { error } = this.state;
    if (error) {
      switch (error.type) {
        case 'AlreadyRedeemed':
          return (
            <React.Fragment>
              <p className="errorMsg header">Code already redeemed!</p>
              <p className="errorMsg">Ref #: {error.rowId}</p>
              <p className="errorMsg">
                This <b>{error.promoName}</b> code was already redeemed
                {error.redemptionTimestamp && (
                  <span>
                    <br /> on{' '}
                    <u>{format(error.redemptionTimestamp, 'MM/DD/YYYY')}</u>
                  </span>
                )}
                {error.redemptionBranch && (
                  <span>
                    {' '}
                    at{' '}
                    <u>
                      {error.redemptionMerchant} ({error.redemptionBranch})
                    </u>
                  </span>
                )}
                .
              </p>
              {(() => {
                switch (this.state.resendEmailState.type) {
                  case 'InFlight':
                    return (
                      <button onClick={this.resendEmail} className="submitBtn">
                        <Spinner />
                      </button>
                    );
                  case 'Init':
                  case 'Failed':
                    return (
                      <button onClick={this.resendEmail} className="resendBtn">
                        Resend Email{' '}
                      </button>
                    );
                  case 'Done':
                    return <p className="message emailSent">Email successfully sent.</p>;
                }
              })()}
            </React.Fragment>
          );
        case 'PromoNotFound':
          return (
            <p className="errorMsg">
              Redemption code is invalid. <br /> Please enter the correct
              redemption code.
            </p>
          );
        case 'MerchantNotFound':
        case 'BranchNotFound':
          return (
            <p className="errorMsg">
              Branch code is invalid. <br /> Please enter the correct branch
              code.
            </p>
          );
        case 'GSheetsAuthError':
        case 'GSheetsReadError':
        case 'GetGSheetsCredential':
        default:
          return (
            <p className="errorMsg">
              Something went wrong. <br /> Please try again in a while.
            </p>
          );
      }
    } else {
      return <p className="errorPlaceholder">Placeholder</p>;
    }
  };
}

export default App;
